<template>
   <div class="middle_front  relative">
     <div class="front flex flex-col items-center justify-center h-1/5">
       <h1 class="text-3xl font-bold">社会价值</h1>
       <p class="text-gray-500">SOCIAL VALUE</p>
     </div>
     <!-- <div class="view_more flex items-center justify-center absolute top-10 right-20">
       <p class="text-red-500">查看更多</p>
       <svg class="w-6 h-6 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
       </svg>
     </div> -->
     <br>
     <div class="middle flex flex-row h-4/5">
       <div class="middle-front flex flex-col items-center justify-center w-1/5">
         <!-- Middle-front content goes here -->
       </div>
       <div class="middle-content flex flex-col   w-100">
         <div class="middle-content flex flex-col   w-100">
           <div class="flex  items-center  w-100"  v-for="item in dataText" :key="item.id">
             <div class="mt-2 p-1" style="width:50px;height:50px">
              <svg class="w-full h-full"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path fill="currentColor" d="M640 384v64H448a128 128 0 0 0-128 128v128a128 128 0 0 0 128 128h320a128 128 0 0 0 128-128V576a128 128 0 0 0-64-110.848V394.88c74.56 26.368 128 97.472 128 181.056v128a192 192 0 0 1-192 192H448a192 192 0 0 1-192-192V576a192 192 0 0 1 192-192z"></path><path fill="currentColor" d="M384 640v-64h192a128 128 0 0 0 128-128V320a128 128 0 0 0-128-128H256a128 128 0 0 0-128 128v128a128 128 0 0 0 64 110.848v70.272A192.064 192.064 0 0 1 64 448V320a192 192 0 0 1 192-192h320a192 192 0 0 1 192 192v128a192 192 0 0 1-192 192z"></path></svg>
             </div>
             <p class="p-2 text-gray-700 line-height-30px">{{ item.text }}</p>
           </div>
         </div>
       </div>
       <div class="middle-back flex flex-col items-center justify-center w-1/5">
       </div>
     </div>
   </div>
</template>
  <script >
export default {
  name: "MiddleBack",
  data() {
    return {
      dataText: [
        {
          id: 1,
          text: "网约车提供了更多的出行选择，缓解了公共交通的压力，提高了城市交通的效率。同时，其便捷性和高效性促进了城市交通资源的合理配置，减少了非法运营车辆的生存空间",
        },
        {
          id: 2,
          text: "随着电动汽车在网约车中的使用逐渐增多，这有助于减少尾气排放，降低空气污染。同时，通过顺风车等方式，有效促进了资源的集约和分享，进一步降低了碳排放.",
        },
        {
          id: 3,
          text: "网约车提供了更加便捷、舒适的出行方式，满足了人们多样化的出行需求。同时，通过技术创新，如实时定位、智能调度等，提高了出行效率和服务质量。",
        },
        {
          id: 4,
          text: "网约车平台通过建立良好的评价体系和信任机制，增强了乘客和司机之间的信任感。同时，一些平台还通过举办公益活动等方式，传递社会正能量，增进社会信任与温暖.",
        },
        {
          id: 5,
          text: "网约车服务可以将城市中闲置的车辆和司机资源进行有效利用，提高了城市的人员、物资流动效率，降低了运营成本和社会与环境成本。",
        },
        {
          id: 6,
          text: "推动共享经济和互联网经济发展：网约车作为共享经济和互联网经济的重要代表之一，推动了新业态、新模式的发展，为经济增长注入了新的动力。",
        },
        {
          id: 7,
          text: "网约车最大的社会价值之一就是打破了出租车的地方牌照垄断效应，为乘客提供了更多的出行选择和便利。",
        },
      ],
    };
  },
  methods: {
    View_more() {
      this.router.push("/View_more");
    },
  },
};
</script>
  <style>

</style>