<template>
   <div class="max-w-7xl mx-auto flex flex-col relative" >
     <div
       class="container mx-auto flex flex-wrap items-center justify-between"
       style="width: 100%; display: flex; justify-content: space-between"
     >
       <div
         class="hidden md:flex justify-between items-center w-full md:w-auto md:order-1"
         id="mobile-menu-3"
       >
         <ul
           class="flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0 md:text-sm md:font-medium"
         >
           <li>
             <router-link
               to="/"
               class="fadein-bot text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-500 md:p-0 text-2xl"
               aria-current="page"
               >首页
             </router-link>
           </li>
           <li>
             <router-link
               to="/about"
               class="fadein-bot fadein-1 text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-500 md:p-0 text-2xl"
             >
               APP下载
             </router-link>
           </li>
           <li>
             <router-link
               to="/portfolio"
               class="fadein-bot fadein-2 text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-gray-500 md:p-0 text-2xl"
             >
              关于我们
             </router-link>
           </li>
         </ul>
       </div>
       <div class="flex flex-col md:flex-row">
         <img
           src="./assets/logo.png"
           alt=""
           class="w-full md:w-auto"
           style="width:100px"
         />
       </div>
     </div>

     <div class="md:mt-100">
       <router-view />
     </div>
   </div>
   <footer
     class="block md:hidden fixed bottom-0 left-0 right-0 rounded-t-3xl border border-[#383838] bg-[#121212] bg-opacity-80 backdrop-blur-md backdrop-opacity-90"
   >
     <nav class="flex justify-around py-4 text-xs">
       <router-link to="/" class="text-gray-300 hover:text-white "
         >首页</router-link
       >
       <router-link to="/about" class="text-gray-300 hover:text-white "
         >APP下载</router-link
       >
       <router-link to="/portfolio" class="text-gray-300 hover:text-white "
         >关于我们</router-link
       >
     </nav>
   </footer>
</template>

<script>
export default {
   methods: {
     redirectToHome() {
       this.$router.push("/");
     },
   },
};
</script>

<style>
/* Tailwind CSS does not require additional styles for this example */
body{
  width: 100%;
  overflow-x: hidden;
}
</style>