<template>
  <div class="aboutUs">
    <!-- <Header></Header> -->
    <el-scrollbar>
      <div class="container">
        <middle-front></middle-front><br><br>
        <middle-content></middle-content><br><br>
        <middle-back></middle-back><br>
        <middle-society></middle-society>
      </div>
      <site-footer></site-footer><br><br>
      
    </el-scrollbar>
  </div>
</template>

<script>
/* eslint-disable */
import SiteFooter from "@/components/site-footer.vue";
import MiddleFront from "@/components/Middle_front.vue";
import MiddleContent from "@/components/Middle_content.vue";
import MiddleBack from "@/components/Middle_back.vue";
import MiddleSociety from "@/components/Middle_society.vue";
export default {
  name: "HomeView",
  components: {
    SiteFooter,
    MiddleFront,
    MiddleContent,
    MiddleBack,
    MiddleSociety
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
body {
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
}

.typewrite > .wrap {
  border-right: 0.08em solid #fff;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.pict {
  box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
  -webkit-box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
  -moz-box-shadow: 0px 0px 73px -9px rgba(255, 219, 112, 0.44);
}

.fadein-up {
  opacity: 0;
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-from-left {
  opacity: 0;
  animation: fadeInLeft 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-right {
  opacity: 0;
  animation: fadeInRight 0.5s ease-out forwards;
  animation-delay: 500ms;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadein-bot {
  opacity: 0;
  animation: fadeInBot 0.5s forwards;
}

@keyframes fadeInBot {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadein-1 {
  animation-delay: 200ms;
}

.fadein-2 {
  animation-delay: 400ms;
}

.fadein-3 {
  animation-delay: 600ms;
}

.fade-500 {
  animation-delay: 500ms;
}
.aboutUs {
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  /* padding: 30px; */
  box-sizing: border-box;
  /* line-height: 40px; */
}
.footer {
  width: 100%;
  height: 200px;
}
</style>
