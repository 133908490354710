<template>
   <div class="middle_front h-screen relative" style="width:100%">
     <video
       class="w-full h-full object-cover"
       autoplay
       muted
       playsinline
     >
       <source
         src="https://img-ys011.didistatic.com/static/didiglobal/didi-home-video0626.mp4"
         type="video/mp4"
       />
     </video>
     <div class="box absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <div class="box1 flex flex-col items-center justify-center bg-black bg-opacity-50 rounded-full p-10 w-96 h-96">
        <h1 class="h1 text-3xl font-bold text-white">胡杨快车美好出行</h1>
        <h1 class="h2 text-2xl mt-4 text-white text-center">Populus euphratica express for beautiful travel</h1>
    </div>
</div>
   </div>
</template>

<script>
export default {
   name: 'middleFront'
}
</script>

<style>
/* Tailwind CSS does not require additional styles for this example */
</style>