<template>
   <div class="middle_front  relative">
     <div class="front flex flex-col items-center justify-center h-1/5">
       <h1 class="text-3xl font-bold">多重业务 更多选择</h1>
       <p class="text-gray-500">MORE OPTIONS</p>
     </div>
    <div class="container mx-auto relative">
     <div class="lunbo" @mouseenter="clear" @mouseleave="run">
       <div class="img">
         <img :src="dataList[currentIndex]" alt="" class="w-full h-full object-cover" />
       </div>
       <div class="dooted" v-if="dataList.length">
         <ul class="doo flex justify-center mt-4">
           <li
             v-for="(item, index) in dataList"
             :key="index"
             :class="{ 'current': currentIndex === index }"
             @click="gotoPage(index)"
             class="mr-2 text-gray-500 hover:text-gray-700 cursor-pointer"
           ></li>
         </ul>
       </div>
     </div>
   </div>
   </div>
</template>

<script>
export default {
   name: "MiddleBack",
   data() {
     return {
     dataList: [
         'https://website.didiglobal.com/dist/img/model-premier.d458bdf3.png',
         'https://website.didiglobal.com/dist/img/model-luxe.3faf11c1.png',
         'https://website.didiglobal.com/dist/img/model-taxi.b9ed3c67.png',
         'https://website.didiglobal.com/dist/img/model-hitch.de99f2db.png',
         'https://website.didiglobal.com/dist/img/model-enterprise.8f0ccafb.png'
       ],
       currentIndex: 0, // 默认显示图片
       timer: null // 定时器
     };
   },
   created() {
     this.run();
   },
   methods: {
    gotoPage(index) {
       this.currentIndex = index;
     },
     next() {
       if (this.currentIndex === this.dataList.length - 1) {
         this.currentIndex = 0;
       } else {
         this.currentIndex++;
       }
     },
     up() {
       if (this.currentIndex === 0) {
         this.currentIndex = this.dataList.length - 1;
       } else {
         this.currentIndex--;
       }
     },
     clear() {
       clearInterval(this.timer);
     },
     run() {
       this.timer = setInterval(() => {
         this.next();
       }, 2000);
     }
   },
};
</script>

<style>
/* Tailwind CSS does not require additional styles for this example */
</style>