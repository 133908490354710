<template>
  <div class="footer bg-gray-800 text-gray-300  h-12" style="width:100%">
    <div class="footer-container mx-auto px-4 h-12">
      <div class="front flex items-center justify-center h-12" style="width:100%">
        <img src="http://www.liuhuyang.com/assets/logo-B5rVYc7F.png" alt="" class="w-24">
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802038392" target="_blank" class="flex items-center ml-4">
          <img src="https://www.12306.cn/index/images/gongan.png" alt="" class="w-5 h-5 mr-2">
          <p class="text-white text-sm">新公网安备65010602000972</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style>
/* Tailwind CSS does not require additional styles for this example */
</style>