<template>
   <div class="middle_front">
     <!-- <div class="front flex" >
  <h1 class="text-3xl font-bold">资讯</h1>
  <p class="text-gray-500">news</p>
</div> -->
<div class="front flex flex-col items-center justify-center h-1/5">
       <h1 class="text-3xl font-bold">资讯</h1>
       <p class="text-gray-500">news</p>
     </div>
<br>
     <div class="middle">
       <div class="middle-front" style="margin-top:10px">
         <h1 class="text-1xl font-bold text-center">在胡杨的庇护下，每一次出行都是诗与远方</h1><br>
         <div class="text-center">胡杨快车，新疆网约车行业的领军品牌</div>
       </div>
       <br>
<div class="container mx-auto relative">
     <div class="lunbo" @mouseenter="clear" @mouseleave="run">
       <div class="img">
         <img :src="dataList[currentIndex]" alt="" class="w-full max-h-64 object-cover rounded-full" />
       </div>
     </div>
   </div>
   <div class="middle_back">
  <div>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，品质出行的不二之选！</p>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，载你穿越新疆的每一道风景。</p>
    <p class="text-gray-500 mt-4 text-center">出行如此顺畅，只因有胡杨快车相伴！</p>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，让每一段旅程都充满温馨与舒适。</p>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，业界翘楚，服务至上！</p>
    <p class="text-gray-500 mt-4 text-center">让每一次出行都成为一次愉快的旅程！</p>
  </div><br><br>
  <div>
    <p class="text-gray-500 mt-4 text-center">回味那些与胡杨快车共度的美好时光。</p>
    <p class="text-gray-500 mt-4 text-center">创新服务，胡杨快车为你打造完美出行体验。</p>
    <p class="text-gray-500 mt-4 text-center">选择胡杨快车，感受新疆的热情与贴心。。</p>
    <p class="text-gray-500 mt-4 text-center">选择胡杨快车，就是选择品质出行，</p>
    <p class="text-gray-500 mt-4 text-center">让您的每一次出行都充满信赖与舒适。</p>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，驰骋在希望的路上，为你带来无限可能！</p>
    <p class="text-gray-500 mt-4 text-center">胡杨快车，安全、快捷、舒适，一应俱全！</p>
  </div>
</div>
     </div>
   </div>
</template>

<script>
export default {
   data() {
     return {
   dataList: [
        'https://kobe-1325876893.cos.ap-nanjing.myqcloud.com/VY4phiuosSAA82de2cd06a2ac63ae1e3fc6484d6687e.png',
        'https://kobe-1325876893.cos.ap-nanjing.myqcloud.com/XHr9TMMtNqAd851e13c5e3443076254566a3e060fbba.png',
        'https://kobe-1325876893.cos.ap-nanjing.myqcloud.com/IMjx4zD8gpkW43c0fea6d0be993fa67138fc9485c0d6.png'
      ],
      currentIndex: 0, // 默认显示图片
      timer: null // 定时器
     };
   },
  created() {
    this.run();
  },
   methods: {
  gotoPage(index) {
      this.currentIndex = index;
    },
    next() {
      if (this.currentIndex === this.dataList.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    },
    up() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.dataList.length - 1;
      } else {
        this.currentIndex--;
      }
    },
    clear() {
      clearInterval(this.timer);
    },
    run() {
      this.timer = setInterval(() => {
        this.next();
      }, 2000);
    }
   },
};
</script>

<style>
.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0 100%;
  transition: transform 0.5s ease;
}

.carousel-item.active {
  transform: translateX(-100%);
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}
</style>